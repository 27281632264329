/* #####DE 601 ATÉ 768 PX##### */

@media screen and (min-width: 601px) and (max-width: 768px) {
  .container {
    max-width: 768px;
    padding: 0;
  }

  /* HEADER */

  .AcxHeader > .conteudo > img {
    width: 60%;
    margin-bottom: 350px;
  }

  /* BCXSECTIONA */

  .BcxSectionA > .text {
    width: 85%;
  }

  /* BCXSECTIOND */

  .BcxSectionD {
    width: 540px;
  }

  /* BCXSECTIONE */

  .BcxSectionE > .rowCard .texto > h3,
  .BcxSectionE > .rowCard .texto > h3 > span {
    font-size: 43px;
  }

  /* BCXSECTIONF */

  .BcxSectionF > .conteudo > .title > h2,
  .BcxSectionF > .conteudo > .title > h2 > span {
    font-size: 43px;
  }
}

/* #####DE 769 ATÉ 992 PX##### */

@media screen and (min-width: 769px) and (max-width: 922px) {
  .container {
    max-width: 922px;
  }

  /* HEADER */

  .AcxHeader > .conteudo > img {
    width: 60%;
    margin-bottom: 400px;
  }

  /* BCXSECTIONA */

  .BcxSectionA > .text {
    width: 85%;
  }

  .BcxSectionA > .logos > .logocred {
    width: 60%;
  }
  .BcxSectionA > .logos > .parceiros {
    width: 90%;
  }

  /* BCXSECTIOND */

  .BcxSectionD {
    width: 720px;
  }

  /* BCXSECTIONE */

  .BcxSectionE > .rowCard > .texto > h3,
  .BcxSectionE > .rowCard > .texto > h3 > span {
    font-size: 43px;
  }

  .BcxSectionE > .aviso {
    flex-direction: row;
    gap: 50px;
    padding: 30px 100px;
  }

  .BcxSectionE > .aviso > .imgAviso {
    padding-bottom: 0;
  }

  /* BCXSECTIONF */

  .BcxSectionF > .conteudo {
    padding: 0 100px;
  }
  .BcxSectionF > .conteudo > .title > h2,
  .BcxSectionF > .conteudo > .title > h2 > span {
    font-size: 43px;
  }

  /* BCXSECTIONG */

  .BcxSectionG {
    width: 85%;
  }
}

/* #####DE 923 ATÉ 1200 PX##### */

@media screen and (min-width: 923px) and (max-width: 1200px) {
  .container {
    max-width: 1200px;
  }

  /* HEADER */

  .AcxHeader {
    justify-content: center;
    background-image: url(../imgs/banner.jpg);
    background-position: center;
  }

  .AcxHeader > .conteudo > img {
    width: 55%;
    margin-left: -80px;
    margin-bottom: 300px;
  }

  /* BCXSECTIONA */

  .BcxSectionA > .text {
    width: 85%;
  }
  .BcxSectionA > .logos > .logocred {
    width: 50%;
  }
  .BcxSectionA > .logos > .parceiros {
    width: 75%;
  }

  /* BCXSECTIONB */

  .BcxSectionB > .cards {
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 0px;
  }

  /* BCXSECTIONC */

  .BcxSectionC > .cards {
    display: grid;
    grid-template-areas: "a a" "b b";
  }

  /* BCXSECTIOND */

  .BcxSectionD {
    max-width: 960px;
    flex-direction: row;
    gap: 50px;
    align-items: center;
  }

  .BcxSectionD > .descricao,
  .BcxSectionD > .content {
    width: 50%;
  }

  /* BCXSECTIONE */

  .BcxSectionE {
    /* width: 98%; */
    justify-content: center;
  }

  .BcxSectionE > .rowCard {
    display: flex;
    align-items: center;
    text-align: left;
    gap: 25px;
  }

  .BcxSectionE > .micEmp {
    text-align: right;
  }

  .BcxSectionE > .rowCard > .imagem > img {
    min-width: 400px;
  }

  .BcxSectionE > .rowCard > .texto {
    max-width: 450px;
  }

  .BcxSectionE > .rowCard > .texto > h3,
  .BcxSectionE > .rowCard > .texto > h3 > span {
    font-size: 43px;
  }

  .BcxSectionE > .micInd,
  .BcxSectionE > .peqEmp {
    flex-direction: row-reverse;
  }

  .BcxSectionE > .aviso {
    flex-direction: row;
    gap: 50px;
    padding: 30px 100px;
    border-radius: 50px;
  }

  .BcxSectionE > .aviso > .imgAviso {
    padding-bottom: 0;
  }

  /* BCXSECTIONF */

  .BcxSectionF > .conteudo {
    padding: 0 125px;
  }
  .BcxSectionF > .conteudo > .title > h2,
  .BcxSectionF > .conteudo > .title > h2 > span {
    font-size: 43px;
  }

  /* BCXSECTIONG */

  .BcxSectionG {
    width: 85%;
  }
}

/* #####ACIMA DE 1200 PX##### */

@media screen and (min-width: 1201px) {
  .container {
    max-width: 100vw;
    display: flex;
    justify-content: center;
  }

  /* HEADER */

  .AcxHeader {
    width: 100vw;
    align-items: normal;
    background-image: url(../imgs/banner.jpg);
  }

  .AcxHeader > .conteudo > img {
    width: 100%;
    max-width: 550px;
    margin-left: 150px;
    margin-top: 100px;
  }

  /* BCXSECTIONA */

  .BcxSectionA {
    max-width: 1320px;
    flex-direction: row;
    gap: 150px;
    padding: 0 100px;
  }

  .BcxSectionA > .text {
    width: 50%;
  }

  .BcxSectionA > .logos {
    width: 50%;
  }

  .BcxSectionA > .logos > .logocred {
    width: 75%;
  }
  .BcxSectionA > .logos > .parceiros {
    margin-top: 15px;
    width: 100%;
  }

  /* BCXSECTIONB */

  .BcxSectionB {
    width: 100%;
    max-width: 1320px;
  }
  .BcxSectionB > .cards {
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
  }

  /* BCXSECTIONC */

  .BcxSectionC {
    width: 100%;
    max-width: 1320px;
  }
  .BcxSectionC > .cards {
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 0px;
  }

  /* BCXSECTIOND */

  .BcxSectionD {
    max-width: 1320px;
    flex-direction: row;
    gap: 100px;
    align-items: center;
  }

  .BcxSectionD > .descricao,
  .BcxSectionD > .content {
    width: 50%;
  }

  /* BCXSECTIONE */

  .BcxSectionE {
    max-width: 1320px;
    justify-content: center;
  }

  .BcxSectionE > .rowCard {
    display: flex;
    align-items: center;
    text-align: left;
    gap: 50px;
  }

  .BcxSectionE > .micEmp {
    text-align: right;
  }

  .BcxSectionE > .rowCard > .imagem > img {
    min-width: 400px;
  }

  .BcxSectionE > .rowCard > .texto {
    max-width: 500px;
  }

  .BcxSectionE > .rowCard > .texto > h3,
  .BcxSectionE > .rowCard > .texto > h3 > span {
    font-size: 43px;
  }

  .BcxSectionE > .micInd,
  .BcxSectionE > .peqEmp {
    flex-direction: row-reverse;
  }

  .BcxSectionE > .aviso {
    flex-direction: row;
    gap: 50px;
    padding: 30px 100px;
    border-radius: 50px;
  }

  .BcxSectionE > .aviso > .imgAviso {
    padding-bottom: 0;
  }

  /* BCXSECTIONF */

  .BcxSectionF {
    padding-right: 0;
    display: flex;
    justify-content: space-between;
  }

  .BcxSectionF > .conteudo {
    width: 40%;
  }

  .BcxSectionF > .conteudo > .title > h2,
  .BcxSectionF > .conteudo > .title > h2 > span {
    font-size: 43px;
  }

  /* BCXSECTIONG */

  .BcxSectionG {
    max-width: 1320px;
  }
}

/* ##### AJUSTES NECESSÁRIOS ###### */

@media screen and (min-width: 1201px) and (max-width: 1449px) {
  /* BCXSECTIONF */

  #sectionF {
    background: url(../imgs/bg-6_alt.png) no-repeat #00753e fixed right;
  }
}

@media screen and (min-width: 1450px) {
  /* BCXSECTIONF */

  #sectionF {
    background: url(../imgs/bg-6_alt2.png) no-repeat #00753e fixed right;
  }
}
