@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 18px;
  font-family: Montserrat, sans-serif;
}

body {
  max-width: 100%;
}

.container {
  max-width: 600px;
}

/* HEADER */

img#header {
  max-width: 100%;
  display: block;
  height: auto;
}

/* BCXSECTIONA */

.BcxSectionA {
  margin: 100px 0;
  padding: 0 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.BcxSectionA>.text {
  width: 90%;
  text-align: justify;
  margin-bottom: 50px;
}

.BcxSectionA>.text>p {
  padding: 10px 0;
}

.BcxSectionA>.logos {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.BcxSectionA>.logos>.logocred {
  width: 75%;
}

.BcxSectionA>.logos>.parceiros {
  margin-top: 15px;
  width: 100%;
}

/* BCXSECTIONB */

#sectionB {
  background: url(../imgs/bg-noise.png) repeat #00a95a;
}

.BcxSectionB {
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BcxSectionB>.title {
  color: #ffffff;
  font-size: 43px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 100px;
}

.BcxSectionB>.title>span {
  font-size: 43px;
  font-weight: 600;
}

.BcxSectionB>.cards {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
}

.BcxSectionB>.cards>.card {
  min-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
}

.BcxSectionB>.cards>.card>h3 {
  text-align: center;
  color: #ffffff;
  font-weight: 400;
}

/* BCXSECTIONC */

#sectionC {
  background: url(../imgs/bg-noise.png) repeat #0090d6;
}

.BcxSectionC {
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BcxSectionC>.title {
  color: #ffffff;
  font-size: 43px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 100px;
}

.BcxSectionC>.title>span {
  font-size: 43px;
  font-weight: 600;
}

.BcxSectionC>.cards {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
}

.BcxSectionC>.cards>.card {
  min-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
}

.BcxSectionC>.cards>.card>h3 {
  text-align: center;
  color: #ffffff;
  font-weight: 400;
}

.BcxSectionC>.rodape>p {
  margin-top: 100px;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
}

/* BCXSECTIOND */

#sectionD {
  background: url(../imgs/bg-3.jpg) center no-repeat;
  background-size: cover;
}

#sectionD>.container {
  display: flex;
  justify-content: center;
}

.BcxSectionD {
  width: 90%;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
}

.BcxSectionD>.descricao {
  display: flex;
  flex-direction: column;
  color: #ffffff;
}

.BcxSectionD>.descricao>img {
  width: 194px;
}

.BcxSectionD>.descricao>h2 {
  font-size: 43px;
  font-weight: 400;
  margin: 35px 0;
}

.BcxSectionD>.descricao>h2>span {
  font-size: 43px;
  font-weight: 600;
}

.BcxSectionD>.descricao>p {
  margin-top: 15px;
}

.BcxSectionD>.content {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  text-align: center;
}

.BcxSectionD>.content>h3 {
  font-size: 20px;
  padding: 15px;
}

.BcxSectionD>.content>a.btn {
  width: 100%;
  background-color: #212e3a;
  padding: 20px 15px;
  margin: 0 0 30px 0;
  border-radius: 30px;
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  color: #ffffff;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
}

.BcxSectionD>.content>a.btn:hover {
  color: #00a95a;
  background-color: #ffffff;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
}

/* BCXSECTIONE */

#sectionE {
  background: url(../imgs/bg-noise.png) repeat #00a95a;
}

#sectionE>.container {
  display: flex;
  justify-content: center;
}

.BcxSectionE {
  width: 100%;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  color: #ffffff;
}

.BcxSectionE>.title {
  font-size: 43px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 50px;
}

.BcxSectionE>.title>span {
  font-size: 43px;
  font-weight: 600;
}

.BcxSectionE>.rowCard {
  width: 90%;
  text-align: center;
}

.BcxSectionE>.rowCard>.imagem>img {
  max-width: 100%;
}

.BcxSectionE>.rowCard>.texto>h3 {
  font-size: 33px;
  /* text-align: center; */
  color: #004387;
  margin-bottom: 30px;
}

.BcxSectionE>.rowCard>.texto>h3>span {
  font-size: 33px;
  font-weight: 400;
}

.BcxSectionE>.rowCard>.texto>p {
  font-size: 25px;
  margin-bottom: 30px;
}

.BcxSectionE>.rowCard>.texto>p>span {
  color: #004387;
  font-size: 25px;
  font-weight: 600;
}

.BcxSectionE>.aviso {
  width: 90%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #004387;
  border-radius: 30px;
  line-height: 25px;
}

.BcxSectionE>.aviso>.imgAviso {
  padding-bottom: 20px;
}

/* BCXSECTIONF */

#sectionF {
  background: url(../imgs/bg-noise.png) repeat #00753e fixed;
}

.BcxSectionF {
  color: #ffffff;
  padding: 100px 50px;
}

.BcxSectionF>.conteudo>.title>h2,
.BcxSectionF>.conteudo>.title>h2>span {
  text-align: center;
  font-size: 35px;
  font-weight: 400;
}

.BcxSectionF>.conteudo>.title>h2>span {
  font-weight: 600;
}

.BcxSectionF span {
  font-weight: 600;
}

.BcxSectionF>.conteudo>.texto {
  margin-top: 50px;
}

.BcxSectionF>.conteudo>.texto>p {
  margin-bottom: 30px;
  line-height: 25px;
}

.BcxSectionF>.conteudo>.texto>p>ul>li {
  list-style-type: none;
  margin-bottom: 15px;
}

.BcxSectionF>.conteudo>.texto>.inscMunic>a {
  background: #004387;
  color: #ffffff;
  padding: 10px 15px;
  border-radius: 30px;
  text-decoration: none;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
}

.BcxSectionF>.conteudo>.texto>.inscMunic>a:hover {
  color: #004387;
  background: #ffffff;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
}

/* BCXSECTIONG */

#sectionG {
  background: url(../imgs/bg-noise.png) repeat #0090d6 fixed;
}

#sectionG>.container {
  display: flex;
  justify-content: center;
}

.BcxSectionG {
  color: #ffffff;
  padding: 100px 20px;
}

.BcxSectionG>.title {
  margin-bottom: 50px;
}

.BcxSectionG>.title,
.BcxSectionG>.title>span {
  text-align: center;
  font-size: 43px;
  font-weight: 400;
}

.BcxSectionG>.title>span {
  font-weight: 600;
}

.BcxSectionG>.duvida {
  border: 2px #fff solid;
  border-radius: 100px;
  padding: 30px 50px;
  margin-bottom: 15px;
  text-align: center;
}

.BcxSectionG>.duvida>h3 {
  font-size: 25px;
  margin-bottom: 25px;
}

.BcxSectionG>h4 {
  margin-top: 50px;
  font-size: 22px;
  text-align: center;
}

/* BCXFOOTER */

.CcxFooter>.logos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.CcxFooter>.logos>a>img {
  height: 150px;
}